import { Path } from "~/shared/components/Path";
import { createCtx } from "~/shared/lib/context";
import * as Routes from "~/shared/routes";

const paths: Path[] = [
  { label: "Пользователи", path: Routes.UsersPageRoute },
  { label: "Транзакции", path: Routes.PaymentsRoute },
  { label: "Логи балансов", path: Routes.BalanceLogsRoute },
  {
    label: "Управление AI",
    children: [
      { label: "Провайдеры AI", path: Routes.AiProvidersRoute },
      { label: "Подключенные AI", path: Routes.AisRoute },
      { label: "Аккаунты AI", path: Routes.AiProviderAccountsPageRoute },
      { label: "Логи AI", path: Routes.AiRequestsRoute }
    ]
  },
  { label: "Промокоды", path: Routes.PromocodePageRoute }
];

export const [usePaths, PathsProvider] = createCtx(paths, { name: "PathsProvider" });
